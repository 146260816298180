import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { Svg } from 'src/elements/Svg';
import { PreTitle, Text } from 'src/elements/Text';
import { tikTokTokensAtom } from 'src/utils/atoms/socialCredentials';
import { useTikTokAuth } from 'src/utils/hooks/useTikTokAuth';

export const TikTokLogin = () => {
  const [tikTokTokens, setTikTokTokens] = useAtom(tikTokTokensAtom);
  const { loading, error, data = tikTokTokens, handleAuthClick } = useTikTokAuth();

  useEffect(() => {
    if (data) {
      setTikTokTokens(data);
    }
  }, [data, setTikTokTokens]);

  return (
    <FlexGroup $vertical $gap={8} $flex={0}>
      <FlexGroup $gap={8} $flex={0}>
        <Svg $size="bodyLarge" name="tiktok" />

        <PreTitle $size="bodyLarge" $color="darkPrimary">
          TIKTOK
        </PreTitle>

        <Spacer />

        {!data && (
          <Button $loading={loading} disabled={!!data} onClick={handleAuthClick}>
            CONNECT
          </Button>
        )}

        {data && <Icon name="check" $color="success" $size="bodyLarge" label="TikTok connected" />}
      </FlexGroup>

      {error && (
        <Text $color="error" role="alert" style={{ marginLeft: 16 }}>
          There was an issue authenticating, please try again later
        </Text>
      )}
    </FlexGroup>
  );
};
